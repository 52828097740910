import React from "react"
import Layout from "../components/layout"
import Map from "../components/map"
import FirstSection from "../components/first_section"
import SecondSection from "../components/second_section"
import ProjectSection from "../components/project_section"

const IndexPage = () => (
  <Layout>
    <FirstSection />
    {/* <SecondSection /> */}
    {/* <ProjectSection /> */}
    {/* <Map /> */}
  </Layout>
)

export default IndexPage
