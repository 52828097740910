import React from "react"
import PropTypes from "prop-types"
import "./layout.scss"
import "@fontsource/montserrat"
import "@fontsource/nunito-sans/400.css"
import "@fontsource/nunito-sans/900.css"
import SEO from "../components/seo"

const Layout = ({ children }) => {
  return (
      <div>
        <SEO title={"Clara Morgeneyer"} />
        <main>{children}</main>
      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
