import React, { useRef, useLayoutEffect, useState } from "react"
// import mapboxgl from "mapbox-gl"
import mapboxgl from '!mapbox-gl';

import "mapbox-gl/dist/mapbox-gl.css"
import Markers from "./markers"
import { siteMetadata } from '../../gatsby-config'
import geojson from '../data/geo_data.js'
import useWindowDimensions from '../../hooks/windowDimensions'

const { mapboxToken } = siteMetadata

const mapContainerStyle = {
  width: "100%",
  height: "90%",
}

const Map = () => {
  const mapContainerRef = useRef(null)
  const [map, setMap] = useState(null)
  const { height, width } = useWindowDimensions();

  useLayoutEffect(() => {
    let zoom, lat, lng;
    if(width < 700){
      lat = 16.686180736829286;
      lng = 40.61973874420886;
      zoom = 0
    } else
    {
      lat = -41.346023;
      lng = 34.079052;
      zoom = 2
    }

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: mapboxToken,
      style: 'mapbox://styles/claramorgen/ck8cm2l5h27oo1iunosq8mf17',
      center: [lat, lng],
      zoom: zoom,
    })
    map.scrollZoom.disable();
    setMap(map)
    return () => map.remove()
  }, [width])

    return (
      <div className="map-section">
        <div>
          <h1 className="digital-nomad">#digitalnomad #cv</h1>
          {/* <p>width: {width} ~ height: {height}</p> */}
        </div>
        <div>

    </div>
        <div className="map-wrapper">
          <div ref={mapContainerRef} style={mapContainerStyle} className="map">
          {geojson.features && map && <Markers map={map} places={geojson.features} />}
          </div>
        </div>
      </div>
    )
  }

export default Map
