import React from "react"

const FirstSection = () => {
    return (
      <div id='first'>
        <h1 className='futura white big'>Clara <span className="last-name">Morgeneyer</span></h1>
        <p className='montserrat white light small'>Software Engineer</p>
        <a className='montserrat white light small text-center line-spaced link' href="mailto:contact@claramorgeneyer.com">
          Contact
        </a>
      </div>
    )
  }

export default FirstSection
